var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitorDetails" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            center: true,
            width: "635px",
            top: "10vh",
            "before-close": _vm.handleClose,
            "show-close": false,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "el-dialog__title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v(" " + _vm._s(_vm.monitorParkName) + " ")]
          ),
          _c("div", { staticClass: "dialogVideo" }, [
            _c(
              "div",
              { staticClass: "monitorBox" },
              _vm._l(_vm.monitorList, function (item, idx) {
                return _c(
                  "div",
                  {
                    key: idx,
                    staticClass: "monitor",
                    on: {
                      click: function ($event) {
                        return _vm.showVideo(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "img onimg" }),
                    _c("div", { staticClass: "monitorName" }, [
                      _vm._v(_vm._s(item.monitorEquipmentName)),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.isShowVideoDialog
        ? _c("video-monitor", {
            ref: "videoMonitor",
            attrs: {
              "passageway-list": _vm.passagewayList,
              isShowChange: _vm.isShowChange,
              title: _vm.title,
              isPlayBack: _vm.isPlayBack,
            },
            on: {
              change: _vm.videoChange,
              close: function ($event) {
                _vm.isShowVideoDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }