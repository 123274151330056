var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-monitor__wrapper",
      class: { display: _vm.dialogVisible },
    },
    [
      _c("div", { staticClass: "modal" }, [
        _c("div", { ref: "title", staticClass: "title-wrapper" }, [
          _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c(
            "div",
            { staticClass: "close-wrapper", on: { click: _vm.close } },
            [_c("img", { attrs: { src: require("./img/closenew.png") } })]
          ),
        ]),
        _c("div", { staticClass: "video-type-box" }, [
          _c(
            "ul",
            { staticClass: "video-line" },
            _vm._l(_vm.videoTypeList, function (item, i) {
              return _c(
                "li",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPlayBack ? true : item.type != 2,
                      expression: "isPlayBack ? true : item.type != 2",
                    },
                  ],
                  key: i,
                  class: _vm.videoType == item.type ? "select" : "",
                  on: {
                    click: function ($event) {
                      return _vm.handleSelectVideoType(item.type)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowPassageway,
                  expression: "isShowPassageway",
                },
              ],
              staticClass: "passageway-wrapper",
            },
            [
              _vm._l(_vm.passagewayList_, function (item, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "passageway",
                      class: { passageway__active: item.isActive },
                      on: {
                        click: function ($event) {
                          return _vm.handlePassagewayClick(item, index)
                        },
                      },
                    },
                    [
                      _c(
                        "p",
                        { attrs: { title: item.monitorEquipmentChannelName } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("textLength")(
                                  item.monitorEquipmentChannelName
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowChange,
                      expression: "isShowChange",
                    },
                  ],
                  staticClass: "change",
                  on: { click: _vm.change },
                },
                [_c("span", [_vm._v("换一批")])]
              ),
            ],
            2
          ),
        ]),
        _c(
          "div",
          {
            ref: "videoLayout",
            class: _vm.isShowPassageway ? "video-wrapper" : "video-wrapper1",
          },
          [
            _c("layout-index", {
              ref: "layout",
              attrs: {
                layout: _vm.layout,
                videoType: _vm.videoType,
                layoutData1: _vm.layoutData1,
                layoutData2: _vm.layoutData2,
                layoutData3: _vm.layoutData3,
                layoutData4: _vm.layoutData4,
                layoutData5: _vm.layoutData5,
                layoutData6: _vm.layoutData6,
                layoutData7: _vm.layoutData7,
              },
              on: { "item-click": _vm.layoutClick },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "footer-wrapper" },
          [
            _c("el-date-picker", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isPlayBack && _vm.videoType == 2,
                  expression: "isPlayBack && videoType == 2",
                },
              ],
              staticClass: "play-back-date",
              attrs: {
                "popper-class": "black_picker",
                type: "datetimerange",
                clearable: false,
                "picker-options": _vm.pickerOptions,
                "start-placeholder": "回放开始日期",
                "end-placeholder": "回放结束日期",
              },
              on: { change: _vm.handlePlayBackChange },
              model: {
                value: _vm.videoPlayBackDate,
                callback: function ($$v) {
                  _vm.videoPlayBackDate = $$v
                },
                expression: "videoPlayBackDate",
              },
            }),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", content: "1x1", placement: "top" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon-one",
                    on: {
                      click: function ($event) {
                        return _vm.iconClick("1*1")
                      },
                    },
                  },
                  [_c("icon-one")],
                  1
                ),
              ]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", content: "2x2", placement: "top" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon-two",
                    on: {
                      click: function ($event) {
                        return _vm.iconClick("2*2")
                      },
                    },
                  },
                  [_c("icon-two")],
                  1
                ),
              ]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", content: "1+2", placement: "top" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon-four",
                    on: {
                      click: function ($event) {
                        return _vm.iconClick("1+2")
                      },
                    },
                  },
                  [_c("icon-four")],
                  1
                ),
              ]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", content: "1+5", placement: "top" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon-five",
                    on: {
                      click: function ($event) {
                        return _vm.iconClick("1+5")
                      },
                    },
                  },
                  [_c("icon-five")],
                  1
                ),
              ]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", content: "3+4", placement: "top" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon-six",
                    on: {
                      click: function ($event) {
                        return _vm.iconClick("3+4")
                      },
                    },
                  },
                  [_c("icon-six")],
                  1
                ),
              ]
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", content: "2+6", placement: "top" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "icon-seven",
                    on: {
                      click: function ($event) {
                        return _vm.iconClick("2+6")
                      },
                    },
                  },
                  [_c("icon-seven", { attrs: { width: 23 } })],
                  1
                ),
              ]
            ),
            _c("img", {
              attrs: { src: require("./img/fullScreen.png") },
              on: { click: _vm.fullScreenClick },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }